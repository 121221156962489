import Quote from './quote';

const quotes: Quote[] = [
  {
    text:
      '"This course helped me realize that I have more control than I thought I did."',
    from: 'Junior, Camarillo, CA',
  },
  {
    text: '"I feel more at ease after doing the Daily Beats."',
    from: 'Senior, Redmond, Washington',
  },
  {
    text: '"The exercises helped me remain calm in stressful situations."',
    from: 'Senior, Arlington Heights, IL',
  },
  {
    text:
      '"This helped me realize that I have control over my life and how I perceive my experiences."',
    from: 'Freshman, Glenbrook, IL',
  },
  {
    text:
      '"The course offered advice that will help me for the rest of my life."',
    from: 'Senior, Redmond, Washington',
  },
  {
    text: '"It helped me manage stress, which helped me focus."',
    from: 'Junior, Ridgeland, Mississippi',
  },
  {
    text: '"After the first daily beat, I felt really calm and lifted up."',
    from: 'Junior, Ridgeland, Mississippi',
  },
];

export default quotes;
