import React from 'react';
import { Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';

interface Props {
  duration?: number;
  rotation?: number;
  toXPx?: number;
  toYPx?: number;
  triggerElement: string;
}

const Disappearing: React.FC<Props> = ({
  children,
  duration = 400,
  rotation = 270,
  toXPx = 0,
  toYPx = 0,
  triggerElement,
}: React.PropsWithChildren<Props>) => {
  return (
    <Controller>
      <Scene duration={duration} triggerElement={triggerElement}>
        <Tween
          to={{ x: `${toXPx}px`, y: `${toYPx}px`, opacity: 0, rotation }}
          from={{ opacity: 1 }}
        >
          {children}
        </Tween>
      </Scene>
    </Controller>
  );
};

export default Disappearing;
