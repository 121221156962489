import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';

import HeaderBrain from './headerBrain';
import { Slant, SlantAngle, SlantPosition } from './slant';

const headerShrinkBreakpoint = 1280;

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      position: 'relative',
      zIndex: 1,
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        bottom: -65,
      },
      justifyContent: 'center',
    },
    headerSection: {
      paddingBottom: 43,
      paddingTop: 0,
      [theme.breakpoints.between('md', headerShrinkBreakpoint)]: {
        paddingBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 30,
        paddingBottom: 0,
        marginBottom: -32,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 84,
      },
    },
    headlinePrimary: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
      fontSize: '84px',
      letterSpacing: 2,
      lineHeight: '84px',
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        fontSize: '64px',
        letterSpacing: 1.52,
        lineHeight: '64px',
      },
    },
    headlineSecondary: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
      fontSize: '75px',
      letterSpacing: 1.78,
      lineHeight: '75px',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        fontSize: '48px',
        letterSpacing: 1.33,
        lineHeight: '48px',
        fontWeight: 700,
      },
    },
    subHeader: {
      lineHeight: '110%',
    },
  };
});

interface Props {
  headlinePrimary: string;
  headlineSecondary: string;
  subtitle?: string;
  isStudentPage?: boolean;
}

const PageHeader: React.FC<Props> = ({
  headlinePrimary,
  headlineSecondary,
  subtitle,
  isStudentPage = false,
}: Props) => {
  const classes = useStyles({});
  const theme = useTheme();
  const isDisplayingAsRow = useMediaQuery(theme.breakpoints.up(960));

  return (
    <Grid
      container
      style={{
        backgroundImage: 'linear-gradient(-180deg, #4D4E69 0%, #2C2E3B 99%)',
        position: 'relative',
      }}
    >
      <Container className={classes.headerSection}>
        <Grid container className={classes.header}>
          <Grid item xs={12} md={6}>
            <HeaderBrain />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              textAlign="center"
              mt={isStudentPage && isDisplayingAsRow ? 18 : 10}
            >
              <Typography
                component="h1"
                color="textSecondary"
                className={classes.headlinePrimary}
              >
                {headlinePrimary}
              </Typography>
              <Typography
                component="h1"
                gutterBottom
                color="textSecondary"
                className={classes.headlineSecondary}
              >
                {headlineSecondary}
              </Typography>
            </Box>
            {subtitle && (
              <Box textAlign="center" width="60%" margin="auto">
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.subHeader}
                >
                  {subtitle}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <Slant position={SlantPosition.BELOW} angle={SlantAngle.RIGHT_TO_LEFT} />
    </Grid>
  );
};

export default PageHeader;
