import Quote from './quote';

const quotes: Quote[] = [
  {
    text:
      '"Kids really enjoyed it. Easy to integrate into my health class. I will absolutely use this for the foreseeable future."',
    from: 'Jeffrey Bailey, High School Teacher',
  },
  {
    text:
      '"This is exactly what I have been looking for. It’s PERFECT for high school students!"',
    from: 'Heidi Aberi, High School Teacher',
  },
  {
    text:
      '"I noticed that students had much higher focus after doing the Daily Beats. It was almost weird to see them so relaxed and calm."',
    from: 'Craig Savolainen, High School Teacher',
  },
  {
    text:
      '"I wish I had this when I was in high school. If I had, I would be in a different place in my life right now."',
    from: 'Stephanie Carlton, High School Teacher',
  },
];

export default quotes;
