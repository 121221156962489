import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';

import { brain, brain2x } from '../assets';
import lottieActiveBrain from '../lottie/ActiveBrain.json';

interface Props {
  animated?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  lottieContainer: {
    position: 'relative',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      marginBottom: 40,
      marginLeft: 'auto',
      marginRight: 'auto',
      height: 225,
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 462,
      paddingTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
    },
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const HeaderBrain: React.FC<Props> = ({ animated }: Props) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isScreenLarge = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div className={classes.imgContainer}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: lottieActiveBrain,
          rendererSettings: {
            className: classes.lottieContainer,
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={
          isScreenSmall ? 274 : isScreenMedium ? 464 : isScreenLarge ? 596 : 596
        }
        height={
          isScreenSmall ? 261 : isScreenMedium ? 444 : isScreenLarge ? 512 : 512
        }
        isClickToPauseDisabled={true}
        speed={0.8}
      />
    </div>
  );
};

export default HeaderBrain;
