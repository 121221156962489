import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';

const useStyles = makeStyles((theme: Theme) => {
  return {
    cardText: {
      height: '4.5em',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '4px',
      fontSize: '22px',
      lineHeight: '1.15',
      letterSpacing: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
      },
    },
    cardSmall: {
      width: '100%',
      maxWidth: 336,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: 11,
      paddingTop: 40,
      paddingBottom: 22,
      paddingLeft: 30,
      paddingRight: 30,
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.18)',
    },
    cardTitle: {
      fontFamily: 'Bebas Neue Book, Arial, sans-serif',
      fontSize: '40px',
      letterSpacing: 0,
      lineHeight: '25px',
    },
  };
});

interface Props {
  title?: string;
  subtitle: string;
  // eslint-disable-next-line
  lottieAnimationData: any;
}

const Card: React.FC<Props> = ({
  title,
  subtitle,
  lottieAnimationData,
}: Props) => {
  const classes = useStyles({});

  return (
    <Box textAlign="center" className={classes.cardSmall} p={5}>
      {title && (
        <Typography
          component="h5"
          gutterBottom
          color="textPrimary"
          className={classes.cardTitle}
          style={{ letterSpacing: 1, marginBottom: '16px' }}
        >
          {title}
        </Typography>
      )}
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: lottieAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={148}
        height={148}
        isClickToPauseDisabled={true}
      />
      <Typography
        variant="subtitle1"
        color="textPrimary"
        className={classes.cardText}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Card;
