import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/carouselOverrides.css';

import { Typography } from '@material-ui/core';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import studentQuotes from '../quotes/student';
import teacherQuotes from '../quotes/teacher';

interface Props {
  type: 'teacher' | 'student';
}

const QuoteCarousel: React.FC<Props> = ({ type }: Props) => {
  const quotes = type === 'teacher' ? teacherQuotes : studentQuotes;

  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      infiniteLoop={true}
      dynamicHeight={true}
      autoPlay={true}
      interval={6000}
      transitionTime={600}
      showStatus={false}
    >
      {quotes.map((quote, index) => (
        <div key={index}>
          <Typography
            variant="subtitle1"
            gutterBottom
            color="textSecondary"
            style={{
              fontSize: '1.35rem',
              fontStyle: 'italic',
              margin: 15,
              lineHeight: 1.5,
            }}
          >
            {quote.text}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            color="textSecondary"
            style={{ fontSize: '0.95rem', marginBottom: 15 }}
          >
            -{quote.from}
          </Typography>
        </div>
      ))}
    </Carousel>
  );
};

export default QuoteCarousel;
